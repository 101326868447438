import { useCallback, useState } from 'react'
import { useQuery } from 'react-query'
import * as ProductService from '../services/product-service'
import { debounce } from 'lodash'

export default function useProductSearchSelect(csrfToken) {
  const [searchTerm, setSearchTerm] = useState('')
  const [selectedSearchResults, setSelectedSearchResults] = useState({})
  const [preSelectedProductIds, setPreSelectedProductIds ] = useState('')
  const [totalPrice, setTotalPrice] = useState('')

  const {
    data: products,
    isFetching: isSearching,
  } = useQuery(
    ['getProducts', searchTerm],
    () => ProductService.getProducts({ searchTerm, csrfToken }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!searchTerm,
    }
  )

  const {
    data: preSelectedProducts,
  } = useQuery(
    ['getProducts', preSelectedProductIds],
    () => ProductService.getProductsWithIds({ preSelectedProductIds, csrfToken }),
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: !!preSelectedProductIds,
    }
  )

	const includedAlready = gon.product.bundle_asset_list?.split(',')

  const [selected, setSelected] = useState({})
  const [newSearch, setNewSearch] = useState(false)
  const [removed, setRemoved] = useState({})

  const getInitialProducts = async (ids) => {
    const {
        data: preSelectedProducts,
      } = useQuery(
        ['getProducts', preSelectedProductIds],
        () => ProductService.getProductsWithIds({ ids, csrfToken }),
        {
          refetchOnWindowFocus: false,
          keepPreviousData: true,
          enabled: !!preSelectedProductIds,
        }
      )
    return preSelectedProducts
  }

  const handleSelect = (productId) => {
    if (selected[productId]) {
      const { [productId]: _, ...rest } = selected
      setSelected(rest)
    } else {
      setSelected({
      ...selected,
      [productId]: true
    })
    }
  }

  const handleAddToBundle = (resultId, action, license_id) => {
    if (action === 'add') {
      setSelectedSearchResults({
        ...selectedSearchResults,
        [resultId]: products.find(({ id }) => id === resultId)
      })
      setSelected(products.find(({ id }) => id === resultId))
    } else {
      const { [resultId]: _, ...rest } = selectedSearchResults
      setSelectedSearchResults(rest)
      setRemoved(selectedSearchResults[resultId])
    }
  }

  const debouncedSetSearchTerm = useCallback(
    debounce((term) => {
      setSearchTerm(term)
      setNewSearch(true)
    }, 1000), []
  )

  return {
    searchResults: products,
    getInitialProducts,
    preSelectedProducts,
    preSelectedProductIds,
    setPreSelectedProductIds,
		setSelectedSearchResults,
    selected,
    removed,
    setRemoved,
    newSearch,
    setNewSearch,
    searchTerm,
    setSelected: handleSelect,
    setSearchTerm: debouncedSetSearchTerm,
    isSearching,
    handleAddToBundle,
    selectedSearchResults,
    totalPrice,
    setTotalPrice,
  }
}