/* global I18n, gon */
import React, { useContext, useState, useRef, useCallback, useEffect } from 'react'
import ProductSearchResult from './ProductSearchResult'
import Skeleton from 'react-loading-skeleton'
import { ProductContext } from '../../contexts/ProductContext'
import { BundlesContext } from './BundlesProvider'
import BundlesProductSelection from './BundlesProductSelection'
import AlertBox from '../../shared/AlertBox'


const i18nOptions = {
  scope: 'turbosquid.products.bundles',
}

export default function BundlesProductSelector() {
  const { product: { collectionProducts }} = useContext(ProductContext)
  const {
    searchSelect
  } = useContext(BundlesContext)

  const {
    setSearchTerm,
    handleAddToBundle,
    searchResults,
    isSearching,
    selectedSearchResults,
		setSelectedSearchResults,
		getInitialProducts,
		preSelectedProducts,
		setPreSelectedProductIds,
		setTotalPrice
  } = searchSelect

	const startingProducts = getInitialProducts(gon.products)
	
	setPreSelectedProductIds(gon.products)

  const selectedProducts = { ...selectedSearchResults }

	useEffect(() => {
		setSelectedSearchResults({ ...preSelectedProducts })
  }, [preSelectedProducts]);

	const calculateTotalPrice = (products) => {
		return Object.values(products).reduce((total, currentHash) => {
			if (currentHash && currentHash.price) {
				let price = Number(currentHash.price.toString().replace(/[^0-9.-]+/g,""))
				return total + price
			}
			return total
		}, 0);
	}

	useEffect(() => {
		let total = calculateTotalPrice(selectedProducts)
		setTotalPrice(`${total.toFixed()}`)
  }, [selectedProducts])

  const selectedProductsArray = []
  Object.keys(selectedProducts).forEach((p) => {
    selectedProductsArray.push(selectedProducts[p])
  })
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  }

	const closeDropdown = useCallback(() => {
    setIsOpen(false);
  }, [])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown()
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  }, [closeDropdown])

  return (
    <div>
			<div className="product-search-select" style={{padding: '15px', position: 'relative'}}>
				<div className="turbosquid_product_form_name dropdown">
					<label className="control-label input-description">
						{I18n.t('add_products', i18nOptions)}
					</label>
					<a href={'#'} target="_blank" rel="noopener noreferrer" className="publishing-link-holder">
							<i className="fa fa-question-circle" title={'hello world'} />
					</a>
					<a href={'#'} target="_blank" rel="noopener noreferrer" className="publishing-link-holder">
							<i className="fa fa-question-circle" title={'hiiii'} />
						</a>
					<input
						onChange={(e) => {setSearchTerm(e.target.value)}}
						onClick={() => toggleDropdown()}
						className="form-control"
						data-testid="bundles-product-selector"
						placeholder={'Enter Product Id or Start Typing A Name'}
						style={{marginBottom: '0', width: '80%'}}
					/>
				</div>
				{ isOpen && <div ref={dropdownRef} className="results-container"style={{padding: isSearching ? '2px' : '0px', width: '78%'}}>
						{ (isSearching) && <Skeleton count={3} />}
						{!isSearching && searchResults && searchResults.map((product) => (
							<div key={product.id} className="result">
								<ProductSearchResult
									onChange={(e) => handleAddToBundle(product.id, 'add', product.license_id)}
									product={product}
								/>
							</div>
						))}
					</div>}
				<hr width="100%" size="2" />
				<div>
					<span style={{fontWeight: 'bold'}}>
						{I18n.t('header', i18nOptions)}
					</span>
				</div>
				<div>
					{`${selectedProductsArray.length} ${selectedProductsArray.length == 1 ? 'Product' : 'Products'}`}
				</div>
				{ selectedProductsArray.map(e => e.license_id).includes(6) &&	
					<div className='editorial-alert'>
						<AlertBox 
							type={'info'}
							message={I18n.t('editorial_only', i18nOptions)}
							info
						/>
					</div>
				}
				<div className="select-container">
					<div className="results-container2">
						<BundlesProductSelection data={selectedProductsArray} />
					</div>
					{ (selectedProductsArray.length == 0) && (<div className='bundles-footer'>
						<span>
							{I18n.t('footer', i18nOptions)}
						</span>
					</div>)}
				</div>
			</div>
    </div>
  )
}