import { get, patch, post } from './service'

const mapProduct = product => {
  return ({
      id: product.turbosquid_id,
      name: product.name,
      thumbnail: product.thumbnail_path,
      price: product.price,
      license_id: product.license_id
    })
}

const mapProductFromId = product => {
  return ({
      id: product.data[0].turbosquid_id,
      name: product.data[0].name,
      thumbnail: product.data[0].thumbnail_path,
      price: product.data[0].price
    })
}

const parsePrice = (value, denominator, currency) => {
  const amount = value / denominator;
  const options = { style: 'currency', currency: currency }
  const formattedAmount = new Intl.NumberFormat('en-US', options).format(amount)

  return formattedAmount.toString()
}

const mapProducts = (products) => {
  return products.filter(p => (!p.bundle))
                 .filter(p => (!p.feature_assignments.some(f => f.name === "Collection")))
                 .filter(p => (p.license_id !== 7))
                 .map(mapProduct)
}

export async function getProduct({ id, csrfToken }) {
  const url = `/turbosquid/products?turbosquid_ids=${id}`
  const resp = await get(url, { authenticity_token: csrfToken })
  return resp ? mapProductFromId(resp) : null
}

export async function getProducts({ ids, searchTerm, csrfToken }) {
  const url = `/turbosquid/products?size=15&term=${searchTerm}`
  const resp = await get(url, { authenticity_token: csrfToken })
  return resp?.data ? mapProducts(resp.data) : []
}

export async function getProductsWithIds({ ids, csrfToken }) {
  const productPromises = ids.split(',').map(
		(id) => getProduct({ id, csrfToken })
  )
  return Promise.all(productPromises).then((products) => {
    return products.reduce((acc, product) => {
        return ({
            ...acc,
            [product.id]: product
          })
    } , {})
  })
}

export async function publishBundleProduct(options, csrfToken, publish) {
	const url = `/turbosquid/products/0`
  let resp
  if (publish) {
    resp = await patch(url, { authenticity_token: csrfToken, turbosquid_product_form: options, publish: publish })
  } else {
    resp = await patch(url, { authenticity_token: csrfToken, turbosquid_product_form: options })
    
  }
	if (publish) {
		window.location.href = gon.turbosquid_products_path
	}

  return resp?.data ? resp.data : []
}